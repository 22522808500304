<template>
  <v-expansion-panel class="mt-10" v-show="historyChanges.status">
      <v-expansion-panel-header>
          {{ $t('historyChanges') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="container">
          <span>{{ $t("lastUpdate") }}</span>
          <p>{{ historyChanges.date }}</p>
          <span v-show="historyChanges.status">{{ $t("status") }}</span>
          <p v-show="historyChanges.status">{{ historyChanges.status ? $t(items.find((value) => value.status === historyChanges.status).name) : '' }}</p>
          <span>{{ $t("lastStatus") }}</span>
          <p>{{ historyChanges.previousState ?
            $t(items.find((value) => value.status === historyChanges.previousState).name) :
            $t("noPreviousState") }}</p>
          <span v-show="historyChanges.comment">{{ $t("comment") }}</span>
          <p>{{ historyChanges.comment }}</p>
          <span v-show="historyChanges.review">{{ $t("review") }}</span>
          <p>{{ historyChanges.review }}</p>
        </div>
      </v-expansion-panel-content>
  </v-expansion-panel>
  </template>

<script>

export default {
  name: 'HistoryChanges',
  props: {
    historyChanges: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      items: [
          {
            status: 10,
            name: 'new'
          },
          {
            status: 20,
            name: 'rejected'
          },
          {
            status: 30,
            name: 'readyToExecute'
          },
          {
            status: 40,
            name: 'inProgress'
          },
          {
            status: 60,
            name: 'clientCancel'
          },
          {
            status: 70,
            name: 'proskerComplete'
          },
          {
            status: 80,
            name: 'clientRejected'
          },
          {
            status: 90,
            name: 'complete'
          },
          {
            status: 100,
            name: 'proskerHelpRequest'
          },
          {
            status: 110,
            name: 'clientHelpRequest'
          }
      ]
    };
  }
};
</script>
<style scoped>
  .container {
    display: flex;
    flex-direction: column;
  }

  .container span {
    font-weight: bold;
  }

  .container p {
    margin-left: 12px;
  }
</style>
