<template>
  <v-expansion-panel class="mt-10">
      <v-expansion-panel-header>
          {{ $t('personalInfos') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="container">
          <span>{{ $t("clientName") }}</span>
          <p>{{ clientData.first_name }} {{ clientData.last_name }}</p>
          <span>{{ $t("clientPhone") }}</span>
          <p>{{ clientData.phone_number }}</p>
          <span>{{ $t("clientEmail") }}</span>
          <p>{{ clientData.email }}</p>
          <span>{{ $t("proskerName") }}</span>
          <p>{{ proskerData.first_name }} {{ proskerData.last_name }}</p>
          <span>{{ $t("proskerPhone") }}</span>
          <p>{{ proskerData.phone_number }}</p>
          <span>{{ $t("proskerEmail") }}</span>
          <p>{{ proskerData.email }}</p>
        </div>
      </v-expansion-panel-content>
  </v-expansion-panel>
  </template>

<script>

export default {
  name: 'ContactInformation',
  props: {
    clientData: {
      type: Object,
      required: true
    },
    proskerData: {
      type: Object,
      required: true
    }
  }
};
</script>
<style scoped>
  .container {
    display: flex;
    flex-direction: column;
  }

  .container span {
    font-weight: bold;
  }

  .container p {
    margin-left: 12px;
  }
</style>
