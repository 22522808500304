<template>
  <v-expansion-panel class="mt-10">
      <v-expansion-panel-header>
          {{ $t('Services') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="container">
          <span>{{ $t("title") }}</span>
          <p>{{ serviceData.title }}</p>
          <span>{{ $t("price") }}</span>
          <p>$ {{ serviceData.price }}</p>
          <span>{{ $t("status") }}</span>
          <p>{{ $t(items.find((value) => value.status ===serviceData.currentState).name) }}</p>
          <span>{{ $t("startDate") }}</span>
          <p>{{ serviceData.startDate }}</p>
          <span>{{ $t("endDate") }}</span>
          <p>{{ serviceData.endDate }}</p>
        </div>
      </v-expansion-panel-content>
  </v-expansion-panel>
  </template>

<script>

export default {
  name: 'Services',
  props: {
    serviceData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      items: [
          {
            status: 10,
            name: 'new'
          },
          {
            status: 20,
            name: 'rejected'
          },
          {
            status: 30,
            name: 'readyToExecute'
          },
          {
            status: 40,
            name: 'inProgress'
          },
          {
            status: 60,
            name: 'clientCancel'
          },
          {
            status: 70,
            name: 'proskerComplete'
          },
          {
            status: 80,
            name: 'clientRejected'
          },
          {
            status: 90,
            name: 'complete'
          },
          {
            status: 100,
            name: 'proskerHelpRequest'
          },
          {
            status: 110,
            name: 'clientHelpRequest'
          }
      ]
    };
  }
};
</script>
<style scoped>
  .container {
    display: flex;
    flex-direction: column;
  }

  .container span {
    font-weight: bold;
  }

  .container p {
    margin-left: 12px;
  }
</style>
