<template>
  <v-main class="container-prosker">
      <v-icon class="mt-5 mb-0" @click="goBack">mdi-arrow-left</v-icon>
      <v-row>
        <template>
            <v-expansion-panels v-model="panel" multiple class="mb-10">
                <ContactInformation :clientData="clientData" :proskerData="proskerData" />
                <Services :serviceData="serviceData" />
                <HistoryChanges :historyChanges="historyChanges" />
            </v-expansion-panels>
        </template>
      </v-row>
  </v-main>
</template>

<script>
  import moment from 'moment';

  import ContactInformation from '../../components/socialvue/servicesDetails/ContactInformation.vue';
  import Services from '../../components/socialvue/servicesDetails/Services.vue';
  import HistoryChanges from '../../components/socialvue/servicesDetails/HistoryChanges.vue';

  export default {
    name: 'JobsAdminDetails',
    components: {
      ContactInformation,
      Services,
      HistoryChanges
    },
    data () {
      return {
        clientData: {},
        proskerData: {},
        serviceData: {},
        historyChanges: {}
      };
    },
    created () {
      this.getQueryData();
    },
    methods: {
      getQueryData () {
        const query = this.$route.params.query;

        this.clientData = query.client_user;
        this.proskerData = query.prosker_user;

        this.serviceData = {
          title: query.title,
          price: query.price,
          currentState: query.status,
          startDate: moment(moment.utc(query.start_date)).format(
            'DD-MM-YYYY'
          ),
          endDate: moment(moment.utc(query.end_date)).format(
            'DD-MM-YYYY'
          )
        };

        this.historyChanges = {
          date: moment(moment.utc(query.updated_at)).format(
            'DD-MM-YYYY'
          ),
          status: query.audits_data ? query.audits_data[0].new_values.status : 0,
          previousState: query.audits_data ? query.audits_data[0].old_values.status : 0,
          comment: query.reason_help,
          review: query.review,
          commentReview: query.comment_review
        }
      },
      goBack () {
        this.$router.go(-1);
      }
    }
};
</script>
